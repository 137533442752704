import Vue from 'vue'

import VueDayjs from 'vue-dayjs-plugin'
import vuescroll from 'vuescroll'
import { Capacitor } from '@capacitor/core'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify'
import nosework from './plugins/nosework.js'
import duration from './plugins/duration.js'
import heartbeats from './plugins/heartbeats'

import i18n from './i18n'

let sentryKey = null
if (Capacitor.getPlatform() === 'web' && (window.location.hostname === 'testapp.skkstart.se' || window.location.hostname === 'localhost')) {
  sentryKey = 'https://646c66840a4545f9b15fa9313665d2eb@o4504163727441920.ingest.sentry.io/4504163728556032'
} else if (Capacitor.getPlatform() === 'web') {
  sentryKey = 'https://o4504163727441920.ingest.sentry.io/4504163905503232'
}

// You can set global config here.
Vue.use(vuescroll, {
  ops: {
    // The global config
  },
  name: 'vue-scroll' // customize component name, default -> vueScroll
})

Vue.config.productionTip = false

Vue.use(nosework, {
  store: {
    container: store
  },
  emitter: {
    useStore: true
  }
})

Vue.use(duration)
Vue.use(heartbeats)
Vue.use(VueDayjs)

if (sentryKey) {
  Sentry.init({
    Vue,
    dsn: sentryKey,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'testapp.skkstart.se', 'app.skkstart.se', /^\//]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
