<template>
  <v-app id="app" class="fill-height" style="max-width:500px;margin-left: auto; margin-right: auto;">
    <back-navigation
      v-if="navigation.show"
      :toggle-drawer="toggleDrawer"
      :to="navigation.to"
      :title="navigation.title"
      app
    />

    <v-navigation-drawer
      v-if="!!user"
      v-model="drawer"
      color="black"
      right
      absolute
      dark
      style="z-index:99999"
    >
      <v-list
        nav
        class="pa-4"
      >
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('Nosework App') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('Logged in as') }} {{ user.context_firstname }} {{ user.context_lastname }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item btn ripple @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('Logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item btn ripple @click="synchronize">
          <v-list-item-icon>
            <v-icon>mdi-sync-circle</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('Synchronize') }}</v-list-item-title>
            <v-list-item-subtitle  style="overflow: visible; white-space: normal;" class="orange--text" v-if="!appState.synchronized">
              {{ $t('Unsynchronized changes') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-container
      class="pa-0 ma-0 fill-height align-start"
      style="position: relative; margin: 0 auto !important;"
      @click="closeDrawer"
    >
      <router-view v-if="ready" style="width: 100%; height: 100%;" />

      <loading-progress v-else />
    </v-container>
  </v-app>
</template>

<script>
// API
import {
  Plugins,
  Capacitor
} from '@capacitor/core'

import { mapState } from 'vuex'

import LoadingProgress from './components/loading-progress'
import BackNavigation from './components/back-navigation'

const { StatusBar } = Plugins
const _ = require('lodash')

export default {
  name: 'Home',

  components: {
    BackNavigation,
    LoadingProgress
  },

  data: () => ({
    drawer: false
  }),

  computed: {
    ...mapState({
      navigation: state => state.navigation,
      user: state => state.auth.user
    }),

    authenticated () {
      return this.appState.authenticated
    },

    shouldLogin () {
      return this.appState.shouldLogin
    },

    ready () {
      return this.appState.ready
    },

    readyAndShouldLogin () {
      return (this.appState.ready && this.appState.mustLogin) || this.user === null
    },

    readyAndShouldNotLogin () {
      return this.appState.ready && !this.appState.mustLogin && this.user !== null
    }
  },

  watch: {
    readyAndShouldLogin (value) {
      if (value) {
        this.$router.push('/auth/login').catch(() => {})
      }
    },

    readyAndShouldNotLogin (value) {
      if (value) {
        this.$router.push(_.get(
          this.$store.state.app,
          'abs.event',
          '/event'
        )).catch(() => {})
      }
    }
  },

  created () {
    if (this.readyAndShouldNotLogin) {
      this.$router.push('/event').catch(() => {})
    } else {
      this.$router.push('/auth/login').catch(() => {})
    }

    if (Capacitor.isPluginAvailable('StatusBar')) {
      StatusBar.setOverlaysWebView({
        overlay: false
      })
      StatusBar.show()
    }
  },

  methods: {
    toggleDrawer () {
      this.drawer = !this.drawer
    },

    closeDrawer () {
      this.drawer = false
    },
    logout () {
      this.$nosework.context.auth().logout()
    },

    synchronize () {
      this.appState.showSyncWrapper = true
    }
  }
}
</script>

<style lang="scss">
  #app {
    max-width: 100%;
    overflow: hidden;
    box-shadow: 0px 0px 64px 0px rgba(0,0,0,0.3);
  }
  body {
    background: #e2e2e2;
  }
</style>
